import React from "react";

import { useMediaQuery } from "@mui/material";

import bannerVertical from "assets/banners/banner_mpo_saude_vertical.jpg";
import bannerHorizontal from "assets/banners/banner_mpo_saude_horizontal.jpg";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

function BannerMPO() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={12} lg={12} textAlign="center">
            <MKBox
              component="img"
              alt="MPO Saúde"
              src={isSmallScreen ? bannerVertical : bannerHorizontal}
              width={isSmallScreen ? "100%" : "70%"}
              onClick={() => window.open("https://mposaude.com.br/plans")}
              sx={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BannerMPO;
