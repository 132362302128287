import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "routes";

import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { DataGrid, ptBR } from "@mui/x-data-grid";
import "@mui/x-data-grid/DataGrid/useDataGridProps";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import MKAlert from "components/MKAlert";

import { api } from "../../../lib/axios";

const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

Quill.register("modules/imageResize", ImageResize);

function Store() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link", "image", "video"],
      ["blockquote", "code-block"],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "info",
  });
  const [alertComponentModal, setAlertComponentModal] = useState({
    message: "",
    type: "info",
  });
  const [loading, setLoading] = useState(false);

  const [openNewOrUpdateModal, setOpenNewOrUpdateModal] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const [products, setProducts] = useState([]);
  const [productsGroups, setProductsGroups] = useState([]);
  const [splitAccounts, setSplitAccounts] = useState([]);

  const [user, setUser] = useState({});
  const [cpf, setCpf] = useState("");
  const [id, setId] = useState("");
  const [productsGroupId, setProductsGroupId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [orderColumn, setOrderColumn] = useState(0);
  const [haveStock, setHaveStock] = useState(false);
  const [feature, setFeature] = useState(false);
  const [file, setFile] = useState("");
  const [photo, setPhoto] = useState("");
  const [status, setStatus] = useState(true);
  const [msgConfirmation, setMsgConfirmation] = useState("");
  const [bankInformation, setBankInformation] = useState("");
  const [splitPayment, setSplitPayment] = useState(0);
  const [splitNF, setSplitNF] = useState(false);

  const handleChangeHTML = (html) => {
    setDescription(html);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCpf(cpfFormated);
  };

  const handlePriceChange = (priceInput) => {
    const priceFiltered = String(priceInput)
      .replace(/[^0-9,]/g, "")
      .replace(/^0+/, "");
    const priceWithTwoDecimals = priceFiltered.replace(/(.*,\d{2}).*/g, "$1");
    const priceString = priceWithTwoDecimals
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    setPrice(`R$ ${priceString}`);
  };

  const clearProduct = () => {
    setSplitAccounts([]);
    setUser({});
    setId("");
    setProductsGroupId("");
    setCpf("");
    setName("");
    setDescription("");
    setPrice("");
    setQuantity(0);
    setOrderColumn(0);
    setHaveStock(false);
    setFeature(false);
    setFile("");
    setPhoto("");
    setStatus(true);
    setBankInformation("");
    setSplitPayment(0);
    setSplitNF(false);
    setMsgConfirmation("");
  };

  const getUserInfo = async (userId) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/users/${userId}`);
      setLoading(false);
      return data;
    } catch (error) {
      setAlertComponent({
        message: "Erro na busca do usuário.",
        type: "error",
      });
    }
    setLoading(false);
    return {};
  };

  const setProductInfos = async (prodInfo) => {
    setUser(prodInfo.author);
    const userInfo = await getUserInfo(prodInfo.author.id);
    handleCPF(userInfo.cpf);
    setId(prodInfo.id);
    setProductsGroupId(prodInfo.products_group_id);
    setName(prodInfo.name);
    setDescription(prodInfo?.description || "");
    setPrice(
      parseFloat(prodInfo.price).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    );
    setQuantity(prodInfo?.quantity || 0);
    setOrderColumn(prodInfo?.order_column || 0);
    setHaveStock(prodInfo?.have_stock || false);
    setFeature(prodInfo?.feature || false);
    setFile(prodInfo?.file || "");
    setPhoto(prodInfo?.photo || "");
    setStatus(prodInfo?.status || true);
    setBankInformation(prodInfo?.bank_information || "");
    setSplitPayment(prodInfo?.split_payment || 0);
    setSplitNF(prodInfo?.split_nf || false);
    setMsgConfirmation(prodInfo?.msg_confirmation || "");
  };

  const handleOpenNewModal = (row = null) => {
    clearProduct();
    if (row) {
      setProductInfos(row);
    }
    setOpenNewOrUpdateModal(true);
  };

  const handleCloseNewOrUpdateModal = () => {
    clearProduct();
    setOpenNewOrUpdateModal(false);
  };

  const getSplitAccounts = async (userIdSelected) => {
    setLoading(true);
    try {
      const response = await api.get(`/split_accounts/users/${userIdSelected}`);
      setSplitAccounts(response.data);
    } catch (error) {
      setAlertComponent({
        message: "Erro na busca de contas para split de pagamento.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProductsGroups = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("products_groups");
      setProductsGroups(data);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message ||
          "Erro na busca pelos grupos de produtos",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProducts = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("products");
      setProducts(data);
    } catch (err) {
      setAlertComponent({
        message: err?.response?.data?.message || "Erro na busca pelos produtos",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getUser = async () => {
    setLoading(true);
    const cpfTratado = cpf.replace(/\D/g, "");
    if (cpfTratado.length !== 11) {
      setAlertComponentModal({
        message: "CPF inválido!",
        type: "error",
      });
      setLoading(false);
      return;
    }
    try {
      const response = await api.get("/users", {
        params: {
          cpf: cpfTratado,
        },
      });
      if (response.data.length === 0) {
        setUser({});
        setAlertComponentModal({
          message: "Usuário não encontrado!",
          type: "error",
        });
        setLoading(false);
        return;
      }
      setUser(response.data[0]);
      await getSplitAccounts(response.data[0].id);
    } catch (error) {
      setAlertComponentModal({
        message: "Nenhum usuário com esse CPF, cadastre o novo usuário!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleProduct = async () => {
    if (!bankInformation && splitPayment !== 0) {
      setAlertComponentModal({
        message:
          "Você não pode solicitar split de pagamento sem criar uma conta split para o autor.",
        type: "error",
      });
      return;
    }
    if (!description || !msgConfirmation) {
      setAlertComponentModal({
        message:
          "Detalhes do produto e mensagem de confirmação da compra são importantes para o registro.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      const priceConverted = parseFloat(
        price.replace(/[^0-9,]/g, "").replace(",", ".")
      );
      const formData = new FormData();
      formData.append("products_group_id", productsGroupId);
      formData.append("author_id", user.id);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("price", priceConverted);
      formData.append("quantity", quantity);
      formData.append("order_column", orderColumn);
      formData.append("have_stock", haveStock);
      formData.append("feature", feature);
      formData.append("msg_confirmation", msgConfirmation);
      formData.append("status", status);
      formData.append("bank_information", bankInformation);
      formData.append("split_nf", splitNF);
      formData.append("split_payment", splitPayment);

      if (file) {
        formData.append("file", file);
      }
      if (photo) {
        formData.append("photo", photo);
      }

      if (id) {
        await api.put(`products/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setAlertComponentModal({
          message: "Produto atualizado com sucesso!",
          type: "success",
        });
      } else {
        await api.post("products", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setAlertComponentModal({
          message: "Produto criado com sucesso!",
          type: "success",
        });
      }
      setLoading(false);
      handleCloseNewOrUpdateModal();
    } catch (error) {
      setAlertComponentModal({
        message:
          error?.response?.data?.message || "Erro na criação/edição do produto",
        type: "error",
      });
    }
    await getProducts();
    setLoading(false);
  };

  const extractGroupName = (params) => {
    const nameGrupo = params.row.products_group.name;
    return <span>{nameGrupo}</span>;
  };

  const extractPriceMaskared = (params) => {
    const floatValue = parseFloat(params.row.price);
    const formattedValue = floatValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return <span>{formattedValue}</span>;
  };

  const extractFeatureStatus = (params) => {
    if (params.row.feature) {
      return <span>Destacado</span>;
    }
    return <span>Normal</span>;
  };

  const extractStatus = (params) => {
    if (params.row.status) {
      return <span>Ativo</span>;
    }
    return <span>Inativo</span>;
  };

  const extractAuthor = (params) => {
    if (params.row.author) {
      return <span>{params.row.author.name}</span>;
    }
    return <span>Nenhum</span>;
  };

  const columns = [
    {
      field: "order_column",
      headerName: "Ordem",
      flex: 0.2,
      headerClassName: "dataGrid-header",
    },
    {
      field: "name",
      headerName: "Produto",
      flex: 1,
      headerClassName: "dataGrid-header",
    },
    {
      field: "group_name",
      headerName: "Grupo",
      flex: 0.5,
      headerClassName: "dataGrid-header",
      renderCell: extractGroupName,
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      flex: 0.3,
      headerClassName: "dataGrid-header",
      hide: true,
    },
    {
      field: "price",
      headerName: "Preço",
      flex: 0.4,
      headerClassName: "dataGrid-header",
      renderCell: extractPriceMaskared,
    },
    {
      field: "feature",
      headerName: "Em Destaque",
      flex: 0.4,
      headerClassName: "dataGrid-header",
      renderCell: extractFeatureStatus,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      headerClassName: "dataGrid-header",
      renderCell: extractStatus,
    },
    {
      field: "author_name",
      headerName: "Autor",
      flex: 1,
      headerClassName: "dataGrid-header",
      renderCell: extractAuthor,
      hide: true,
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.2,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenNewModal(row)}>
            <EditIcon />
          </IconButton>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (!productsGroups || Object.keys(productsGroups).length === 0) {
        await getProductsGroups();
      }
      if (!products || Object.keys(products).length === 0) {
        await getProducts();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 4000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertComponentModal.message !== "") {
      setTimeout(() => {
        setAlertComponentModal({
          message: "",
          type: "",
        });
      }, 4000);
    }
  }, [alertComponentModal]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={20}
        width="100%"
        height="100vh"
      >
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Configurações da Loja Virtual
        </Typography>
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          <Grid item xs={12} lg={12} py={1} style={{ textAlign: "center" }}>
            <MKButton
              variant="gradient"
              color="info"
              component={Link}
              to="/administrator-area/store-groups"
            >
              Acessar painel de Grupos dos Produtos
            </MKButton>
          </Grid>
          <Grid item xs={12} lg={12} py={1}>
            <MKBox
              sx={{
                height: "70vh",
                width: "100%",
                paddingX: "10%",
                margin: "10px",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {alertComponent.message !== "" && (
                <Grid item xs={12}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              {products && Object.keys(products).length > 0 && (
                <DataGrid
                  rows={products}
                  columns={columns}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              )}
            </MKBox>
            <Fab
              sx={{ position: "absolute", bottom: 50, left: 30 }}
              color="success"
              aria-label="add"
              onClick={() => handleOpenNewModal()}
            >
              <AddIcon color="white" />
            </Fab>
          </Grid>
        </Grid>
      </MKBox>

      {/* Modal de Criação / Edição */}
      <Dialog
        open={openNewOrUpdateModal}
        onClose={handleCloseNewOrUpdateModal}
        fullScreen={false}
        scroll="body"
        aria-labelledby="create-coupon-title"
        aria-describedby="create-coupon-description"
        maxWidth="xxxl"
        fullWidth
      >
        <DialogTitle id="create-coupon-title" style={styles.dialogTitle}>
          <div>
            <h3>{id ? "Editar Produto" : "Criar Produto"}</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseNewOrUpdateModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {alertComponentModal.message !== "" && (
              <Grid item xs={12}>
                <MKAlert color={alertComponentModal.type}>
                  {alertComponentModal.message}
                </MKAlert>
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <MKInput
                variant="outlined"
                label="CPF"
                name="cpf"
                value={cpf}
                onChange={(e) => handleCPF(e.target.value)}
                onBlur={getUser}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MKInput
                variant="outlined"
                label="Usuário"
                name="userName"
                value={user?.name || ""}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {productsGroups && productsGroups.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel id="productsGroupId">Grupo do Produto</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="productsGroupId"
                    id="productsGroupId"
                    value={productsGroupId ?? ""}
                    label="Usuário"
                    sx={{ height: "45px" }}
                    onChange={(e) => setProductsGroupId(e.target.value)}
                  >
                    {productsGroups.map((prodGroup) => (
                      <MenuItem
                        key={prodGroup.id}
                        value={prodGroup.id}
                        selected={prodGroup.id === productsGroupId}
                      >
                        {prodGroup.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <MKInput
                variant="outlined"
                label="Nome do Produto"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <MKInput
                variant="outlined"
                label="Preço"
                name="price"
                value={price}
                onChange={(e) => handlePriceChange(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Select
                variant="outlined"
                id="bankInformation"
                value={bankInformation ?? ""}
                sx={{ height: "45px" }}
                onChange={(e) => setBankInformation(e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem disabled value="">
                  Conta de Recebimento
                </MenuItem>
                {splitAccounts.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    selected={item.id === bankInformation}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} md={1}>
              <MKInput
                variant="outlined"
                label="SPLIT (%)"
                name="splitPayment"
                value={splitPayment}
                onChange={(e) => setSplitPayment(+e.target.value)}
                type="number"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6} md={1}>
              <Select
                variant="outlined"
                value={splitNF ?? false}
                sx={{ height: "45px" }}
                onChange={(e) => setSplitNF(e.target.value)}
                disabled={splitPayment === 0}
                displayEmpty
                fullWidth
              >
                <MenuItem value selected={splitNF}>
                  NF Parcial
                </MenuItem>
                <MenuItem value={false} selected={!splitNF}>
                  NF Total
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={2}>
              <MKBox
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Switch
                  checked={!!status}
                  onChange={() => setStatus(!status)}
                />
                <MKTypography
                  variant="body2"
                  sx={{
                    color: status ? "green" : "red",
                    fontWeight: "bold",
                  }}
                >
                  Produto {status ? "ATIVO" : "INATIVO"}
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={12} sx={{ zIndex: 1000 }}>
              <Box
                sx={{ padding: 1, backgroundColor: "#eeeeee", borderRadius: 2 }}
              >
                <AppBar position="static" sx={{ margin: 1, width: "50%" }}>
                  <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab
                      label="Editor Visual HTML"
                      sx={{
                        backgroundColor:
                          activeTab === 0 ? "#eeeeee" : "transparent",
                      }}
                    />
                    <Tab
                      label="Editor Código Fonte"
                      sx={{
                        backgroundColor:
                          activeTab === 1 ? "#eeeeee" : "transparent",
                      }}
                    />
                  </Tabs>
                </AppBar>
                <Box
                  sx={{
                    margin: 1,
                    backgroundColor: "#ffffff",
                    borderRadius: 2,
                  }}
                >
                  {activeTab === 0 && (
                    <ReactQuill
                      value={description}
                      onChange={handleChangeHTML}
                      modules={modules}
                      placeholder="Editor Visual HTML"
                    />
                  )}
                  {activeTab === 1 && (
                    <MKInput
                      variant="outlined"
                      label="Editor Código Fonte"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      multiline
                      fullWidth
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="outlined"
                    label="Quantidade"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox
                    width="100%"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      checked={!!haveStock}
                      onChange={() => setHaveStock(!haveStock)}
                    />
                    <MKTypography variant="body2">
                      {" "}
                      Estoque Controlado
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="outlined"
                    label="Ordem de Exibição"
                    name="orderColumn"
                    value={orderColumn}
                    onChange={(e) => setOrderColumn(+e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox
                    width="100%"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      checked={!!feature}
                      onChange={() => setFeature(!feature)}
                    />
                    <MKTypography variant="body2"> Em Destaque</MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    sx={{ width: 100, textAlign: "right", marginRight: 1 }}
                  >
                    Foto:
                  </Typography>
                  <MKInput
                    variant="outlined"
                    name="photo"
                    id="photo"
                    type="file"
                    onChange={(e) => setPhoto(e.target.files[0])}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    sx={{ width: 100, textAlign: "right", marginRight: 1 }}
                  >
                    Arquivo:{" "}
                  </Typography>
                  <MKInput
                    variant="outlined"
                    name="file"
                    id="file"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <MKInput
                variant="outlined"
                label="Texto mensagem confirmação"
                name="msgConfirmation"
                value={msgConfirmation}
                onChange={(e) => setMsgConfirmation(e.target.value)}
                multiline
                rows={5}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {!id && (
                <MKBox width="100%" p={2}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={clearProduct}
                    fullWidth
                  >
                    Limpar
                  </MKButton>
                </MKBox>
              )}
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={handleProduct}
                  fullWidth
                >
                  {id ? "Salvar Alterações" : "Criar Novo Produto"}
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Store;
