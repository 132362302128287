/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import routes from "routes";
import Menu from "components/Menu";
import LogoMPO from "assets/images/logo_horizontal.png";

import {
  Backdrop,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import * as jose from "jose";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import { api } from "../../lib/axios";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");

function Register() {
  const history = useHistory();
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [privacyRead, setPrivacyRead] = useState(false);

  const [loading, setLoading] = useState(false);

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });

  const handleCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCpf(cpfFormated);
  };

  const handleCellPhone = (cellPhoneInput) => {
    const cellPhoneFormated = cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    setCellphone(cellPhoneFormated);
  };

  const isValidCPF = (numbersCPF) => {
    if (numbersCPF.length !== 11) {
      return false;
    }
    if (/^(\d)\1{10}$/.test(numbersCPF)) {
      return false;
    }

    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[9], 10)) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[10], 10)) {
      return false;
    }
    return true;
  };

  const handleSubmitForm = async () => {
    const userCell = `55${cellphone.replace(/\D/g, "")}`;
    const userCPF = cpf.replace(/\D/g, "");
    if (!isValidCPF(userCPF)) {
      setAlertComponent({
        message: "CPF inválido",
        type: "error",
      });
      setLoading(false);
      return false;
    }
    if (
      userCPF.length !== 11 ||
      userCell.length !== 13 ||
      name === "" ||
      password === "" ||
      email === "" ||
      birthDate === "" ||
      birthDate.length !== 10
    ) {
      setAlertComponent({
        message: "Verifique os dados informados",
        type: "error",
      });
      setLoading(false);
      return false;
    }

    if (!privacyRead) {
      setAlertComponent({
        message: "Aceite os termos de uso",
        type: "error",
      });
      setLoading(false);
      return false;
    }

    if (!email.includes("@") || !email.includes(".")) {
      setAlertComponent({
        message: "E-mail inválido",
        type: "error",
      });
      setLoading(false);
      return false;
    }
    setLoading(true);
    try {
      const dataRequest = {
        name: name.trim(),
        cpf: userCPF,
        birth_date: dayjs(birthDate).format("YYYY-MM-DD"),
        email: email.trim(),
        cellphone: userCell,
        password: password.trim(),
      };
      const response = await api.post("users", dataRequest);

      if (response.status === 201 && response.data) {
        const token = response.data;
        localStorage.setItem("token", JSON.stringify(token));

        const newUser = jose.decodeJwt(token).user;
        if (newUser.type === 1) {
          newUser.type = "admin";
        } else if (newUser.type === 3) {
          newUser.type = "professional";
        } else if (newUser.type === 4) {
          newUser.type = "supervisor";
        } else {
          newUser.type = "user";
        }

        localStorage.setItem("user", JSON.stringify(newUser));
        api.defaults.headers.Authorization = `Bearer ${token}`;
        history.push("/user-area");
      } else {
        setAlertComponent({
          message: "Desculpe! Um erro nã previsto aconteceu.",
          type: "error",
        });
      }
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
    return true;
  };

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes.public} sticky />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          paddingTop: "180px",
        }}
      >
        <Card sx={{ minWidth: "50%", maxWidth: "90%" }}>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            mb={1}
            textAlign="center"
          >
            <MKTypography variant="body1" color="white" my={1}>
              Registe-se agora!
            </MKTypography>
            <MKTypography variant="body2" color="white" my={1}>
              Insira seus dados no formulário
            </MKTypography>
          </MKBox>
          <MKBox p={3}>
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <MKBox component="form" role="form">
              <MKBox mb={2}>
                <MKInput
                  type="text"
                  label="Nome"
                  name="name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="text"
                  label="CPF"
                  name="cpf"
                  placeholder="999.999.999-99"
                  fullWidth
                  value={cpf}
                  onChange={(e) => handleCPF(e.target.value)}
                  required
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="date"
                  label="Data de Nascimento"
                  name="birthDate"
                  placeholder="DD/MM/AAAA"
                  fullWidth
                  value={birthDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                  required
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="cellphone"
                  label="Celular"
                  name="cellphone"
                  placeholder="(99) 99999-9999"
                  fullWidth
                  value={cellphone}
                  onChange={(e) => handleCellPhone(e.target.value)}
                  required
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="email"
                  label="E-mail"
                  name="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="password"
                  label="Senha"
                  name="password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </MKBox>
              <MKBox display="flex" alignItems="center" ml={-1}>
                <Checkbox
                  checked={privacyRead}
                  onChange={(e) => setPrivacyRead(e.target.checked)}
                />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Concordo com os&nbsp;
                </MKTypography>
                <MKTypography
                  component="a"
                  href="/privacy-politic"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  Termos de Uso e Política de Privacidade
                </MKTypography>
              </MKBox>
              <MKBox mt={3} mb={1}>
                <MKButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSubmitForm}
                >
                  Registrar
                </MKButton>
              </MKBox>
              <MKBox mt={3} mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Já tem uma conta?{" "}
                  <MKTypography
                    component={Link}
                    to="/login"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Faça o Login
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      </Container>
    </>
  );
}

export default Register;
