import React, { useEffect, useState } from "react";
import routes from "routes";

import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import CustomCheckbox from "components/CustomCheckbox";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
// import DeleteIcon from "@mui/icons-material/Delete";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import { api } from "../../../lib/axios";

const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function Coupons() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState("");
  const [code, setCode] = useState("CODECUPOM");
  const [value, setValue] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [amountOfUse, setAmountOfUse] = useState(0);
  const [target, setTarget] = useState("sistema");
  const [validatedAt, setValidatedAt] = useState(
    dayjs().tz("America/Sao_Paulo")
  );
  const [active, setActive] = useState(true);
  const [professionalServices, setProfessionalServices] = useState([]);
  const [services, setServices] = useState([]);

  const [coupons, setCoupons] = useState([]);
  const [rowsTable, setRowsTable] = useState([]);

  const [openNewModal, setOpenNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const handleSelectionServicesChange = (selected) => {
    setProfessionalServices(selected);
  };

  const handleChange = (newValue) => {
    const dateConverted = dayjs.tz(newValue, "America/Sao_Paulo").toISOString();
    setValidatedAt(dateConverted.substring(0, dateConverted.length - 5));
  };

  const toggleSwitch = () => setActive(!active);

  const clearCoupon = () => {
    setId(null);
    setCode("CODECUPOM");
    setValue(0);
    setQuantity(0);
    setAmountOfUse(1);
    setTarget("sistema");
    setValidatedAt(dayjs().tz("America/Sao_Paulo"));
    setActive(true);
    setProfessionalServices([]);
  };

  const getServices = async () => {
    setLoading(true);
    try {
      const responseServices = await api.get("services");
      setServices(responseServices.data);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getCoupons = async () => {
    setLoading(true);
    try {
      const responseCoupons = await api.get("coupons");
      setCoupons(responseCoupons.data);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const setCouponInfo = (couponInfo) => {
    setId(couponInfo.id);
    setCode(couponInfo.code);
    setValue(couponInfo.value.replace(",", ".").replace("R$ ", ""));
    setQuantity(couponInfo.quantity);
    setAmountOfUse(couponInfo.amountOfUse);
    setTarget(couponInfo.target);
    setValidatedAt(dayjs(couponInfo.validatedAt, "DD/MM/YYYY").toISOString());
    setActive(couponInfo.active === "Ativado");
    setProfessionalServices(couponInfo.professionalServices);
  };

  const handleOpenNewModal = () => {
    clearCoupon();
    setOpenNewModal(true);
  };

  const handleOpenEditModal = (row) => {
    setId(row.id);
    setCouponInfo(row);
    setOpenEditModal(true);
  };

  // const handleOpenDeleteModal = (row) => {
  //   setId(row.id);
  //   setCode(row.code);
  //   setOpenDeleteModal(true);
  // };

  const handleCloseNewModal = () => {
    clearCoupon();
    setOpenNewModal(false);
  };

  const handleCloseEditModal = () => {
    clearCoupon();
    setOpenEditModal(false);
    getCoupons();
  };

  const handleCloseDeleteModal = () => {
    setId("");
    setOpenDeleteModal(false);
  };

  const extractServicesAsString = (servicesReceived) => {
    const servicesArray = [];
    servicesReceived.forEach((item) => {
      const serviceSelected = services.find((serv) => serv.id === item);
      if (serviceSelected) {
        servicesArray.push(serviceSelected.name);
      }
    });
    return servicesArray.join(", ");
  };

  const createCoupon = async () => {
    setLoading(true);
    if (!code || !value || !quantity || !target || !validatedAt) {
      setAlertComponent({
        message: "Preencha os campos corretamente",
        type: "error",
      });
    } else {
      const newCoupon = {
        code,
        value: parseFloat(value),
        quantity: parseInt(quantity, 10),
        amountOfUse: amountOfUse ? parseInt(amountOfUse, 10) : 0,
        target,
        validatedAt,
        active: active ? 1 : 0,
        services: professionalServices,
      };
      try {
        await api.post("coupons", newCoupon);
        setAlertComponent({
          message: "Cupom criado com sucesso!",
          type: "success",
        });
      } catch (err) {
        setAlertComponent({
          message: err.response?.data?.message || "Ocorreu um erro!",
          type: "error",
        });
      }
    }
    setLoading(false);
  };

  const updateCoupon = async () => {
    setLoading(true);
    if (!id || !code || !value || !quantity || !target || !validatedAt) {
      setAlertComponent({
        message: "Preencha os campos corretamente",
        type: "error",
      });
    } else {
      const editCoupon = {
        code,
        value: parseFloat(value),
        quantity: parseInt(quantity, 10),
        amountOfUse: amountOfUse ? parseInt(amountOfUse, 10) : 0,
        target,
        validatedAt,
        active: active ? 1 : 0,
        services: professionalServices,
      };
      try {
        await api.put(`coupons/${id}`, editCoupon);
        setAlertComponent({
          message: "Cupom atualizado com sucesso!",
          type: "success",
        });
        handleCloseEditModal();
      } catch (err) {
        setAlertComponent({
          message: err.response?.data?.message || "Ocorreu um erro!",
          type: "error",
        });
      }
    }
    setLoading(false);
  };

  // const deleteCoupon = async (couponId) => {
  //   setLoading(true);
  //   try {
  //     await api.delete(`coupons/${couponId}`);
  //     setAlertComponent({
  //       message: "Cupom excluído com sucesso!",
  //       type: "success",
  //     });
  //     handleCloseDeleteModal();
  //   } catch (err) {
  //     setAlertComponent({
  //       message: err.response?.data?.message || "Ocorreu um erro!",
  //       type: "error",
  //     });
  //   }
  //   setLoading(false);
  // };

  const columns = [
    {
      field: "code",
      headerName: "Código",
      flex: 0.1,
      headerClassName: "dataGrid-header",
    },
    {
      field: "value",
      headerName: "Valor",
      flex: 0.1,
      headerClassName: "dataGrid-header",
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      flex: 0.07,
      headerClassName: "dataGrid-header",
    },
    {
      field: "target",
      headerName: "Alvo",
      flex: 0.05,
      headerClassName: "dataGrid-header",
    },
    {
      field: "stringServices",
      headerName: "Serviços",
      flex: 0.2,
      headerClassName: "dataGrid-header",
    },
    {
      field: "amountOfUse",
      headerName: "Limite",
      flex: 0.06,
      headerClassName: "dataGrid-header",
    },
    {
      field: "validatedAt",
      headerName: "Validade",
      flex: 0.07,
      headerClassName: "dataGrid-header",
    },
    {
      field: "active",
      headerName: "Ativo",
      flex: 0.06,
      headerClassName: "dataGrid-header",
    },
    {
      field: "actions",
      headerName: "Operações",
      flex: 0.05,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenEditModal(row)}>
            <EditIcon />
          </IconButton>
          {/* <IconButton size="large" onClick={() => handleOpenDeleteModal(row)}>
            <DeleteIcon />
          </IconButton> */}
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      if (services.length === 0) {
        await getServices();
      }
      if (coupons.length === 0) {
        await getCoupons();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (coupons.length > 0) {
      const rows = [];
      coupons.forEach((rowItem) => {
        rows.push({
          id: rowItem.id,
          code: rowItem.code,
          value: `R$ ${rowItem.value},00`,
          quantity: rowItem.quantity,
          amountOfUse: rowItem.amountOfUse,
          target: rowItem.target,
          validatedAt: dayjs(rowItem.validatedAt).format("DD/MM/YYYY"),
          active: rowItem.active === 1 ? "Ativado" : "Desativado",
          professionalServices: rowItem.services,
          stringServices: rowItem.services
            ? extractServicesAsString(rowItem.services)
            : null,
        });
      });
      setRowsTable(rows);
    }
  }, [coupons]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      {/* Modal de Criação */}
      <Dialog
        open={openNewModal}
        onClose={handleCloseNewModal}
        fullScreen={false}
        scroll="body"
        aria-labelledby="create-coupon-title"
        aria-describedby="create-coupon-description"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="create-coupon-title" style={styles.dialogTitle}>
          <div>
            <h3>Criação de Cupom</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseNewModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Código do cupom"
                  name="codigo"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Valor do desconto"
                  name="value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Quantidade de Utilizações"
                  name="quantity"
                  value={quantity}
                  type="number"
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Utilizações por Usuário (0 = ilimitado)"
                  name="amountOfUse"
                  value={amountOfUse}
                  type="number"
                  onChange={(e) => setAmountOfUse(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <FormControl fullWidth>
                  <InputLabel id="target">Produto alvo</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="target"
                    id="target"
                    value={target}
                    sx={{ height: "45px" }}
                    onChange={(e) => setTarget(e.target.value)}
                    required
                  >
                    <MenuItem value="sistema">Sitema de Agendamento</MenuItem>
                    <MenuItem value="loja">Loja Virtual</MenuItem>
                  </Select>
                </FormControl>
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Válido até"
                    inputFormat="DD/MM/YYYY"
                    value={validatedAt}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingRight: 4,
                paddingLeft: 2,
              }}
            >
              <MKBox sx={{ padding: 4 }}>
                <Typography>Selecione os serviços:</Typography>
                <CustomCheckbox
                  data={services}
                  onSelectionChange={handleSelectionServicesChange}
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MKBox
                width="100%"
                py={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Switch checked={active} onChange={toggleSwitch} />
                <MKBox lineHeight={0.5}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="bold"
                  >
                    Ativar / Desativar Cupom
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={clearCoupon}
                  fullWidth
                >
                  Limpar
                </MKButton>
              </MKBox>
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={createCoupon}
                  fullWidth
                >
                  Gravar Dados
                </MKButton>
              </MKBox>
            </Grid>
            {/* </MKBox> */}
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Modal de Edição */}
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        fullScreen={false}
        scroll="body"
        aria-labelledby="edit-coupon-title"
        aria-describedby="edit-coupon-description"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="edit-coupon-title" style={styles.dialogTitle}>
          <div>
            <h3>Edição de Cupom</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseEditModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {alertComponent.message && (
              <Grid item xs={12}>
                <MKAlert color={alertComponent.type}>
                  {alertComponent.message}
                </MKAlert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Código do cupom"
                  name="codigo"
                  value={code}
                  fullWidth
                  readOnly
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Valor do desconto"
                  name="value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Quantidade de Utilizações"
                  name="quantity"
                  value={quantity}
                  type="number"
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <MKInput
                  variant="outlined"
                  label="Utilizações por Usuário (0 = ilimitado)"
                  name="amountOfUse"
                  value={amountOfUse}
                  type="number"
                  onChange={(e) => setAmountOfUse(e.target.value)}
                  fullWidth
                  required
                />
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <FormControl fullWidth>
                  <InputLabel id="target">Produto alvo</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="target"
                    id="target"
                    value={target}
                    sx={{ height: "45px" }}
                    onChange={(e) => setTarget(e.target.value)}
                    required
                  >
                    <MenuItem value="sistema">Sitema de Agendamento</MenuItem>
                    <MenuItem value="loja">Loja Virtual</MenuItem>
                  </Select>
                </FormControl>
              </MKBox>
              <MKBox width="100%" py={1} mx={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Válido até"
                    inputFormat="DD/MM/YYYY"
                    value={validatedAt}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingRight: 4,
                paddingLeft: 2,
              }}
            >
              <MKBox sx={{ padding: 4 }}>
                <Typography>Selecione os serviços:</Typography>
                <CustomCheckbox
                  data={services}
                  preSelectedValues={professionalServices}
                  onSelectionChange={handleSelectionServicesChange}
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MKBox
                width="100%"
                py={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Switch checked={active} onChange={toggleSwitch} />
                <MKBox lineHeight={0.5}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="bold"
                  >
                    Ativar / Desativar Cupom
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={updateCoupon}
                  fullWidth
                >
                  Gravar Dados
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Modal de Exclusão */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        fullScreen={false}
        scroll="body"
        aria-labelledby="delete-coupon-title"
        aria-describedby="delete-coupon-description"
      >
        <DialogTitle id="delete-coupon-title" style={styles.dialogTitle}>
          <div>
            <h3>Exclusão de Cupom</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseDeleteModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <MKBox mx={2} mb={1} pt={1} width="100%">
              <Grid item>
                <MKTypography style={{ width: "100%", textAlign: "center" }}>
                  {code}
                </MKTypography>
              </Grid>
              <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                <MKBox width="100%" p={2}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={() => deleteCoupon(id)}
                    fullWidth
                  >
                    CONFIRMA EXCLUSÃO DO CUPOM
                  </MKButton>
                </MKBox>
              </Grid>
            </MKBox>
          </Grid>
        </DialogContent>
      </Dialog>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={20}
        width="100%"
        height="100vh"
      >
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          <Grid item xs={12} lg={12} py={3}>
            <MKBox
              sx={{
                height: "80vh",
                width: "100%",
                paddingX: "10%",
                margin: "10px",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {alertComponent.message && (
                <Grid item xs={12}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              {rowsTable && (
                <DataGrid
                  rows={rowsTable}
                  columns={columns}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                />
              )}
            </MKBox>
            <Fab
              sx={{ position: "absolute", bottom: 50, left: 30 }}
              color="success"
              aria-label="add"
              onClick={() => handleOpenNewModal()}
            >
              <AddIcon color="white" />
            </Fab>
            {/* </Card> */}
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default Coupons;
