/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import routes from "routes";
import { DataGrid, ptBR } from "@mui/x-data-grid";

// @mui material components
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import PartyModeIcon from "@mui/icons-material/PartyMode";

// Otis Kit PRO components
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKAlert from "components/MKAlert";
import ProfessionalCheckbox from "components/ProfessionalCheckbox";

// images
import LogoMPO from "assets/images/logo_horizontal.png";

// import axios from "axios";
import { api } from "../../../lib/axios";

const styles = {
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function ProfessionalAdminPanel() {
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });

  const [alertModalComponent, setAlertModalComponent] = useState({
    message: null,
    type: null,
  });

  const [loading, setLoading] = useState(false);

  const [userSelected, setUserSelected] = useState({});

  const [cpf, setCpf] = useState("");
  const [id, setId] = useState("");
  const [professionalName, setProfessionalName] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [resume, setResume] = useState("");
  const [photo, setPhoto] = useState("");
  const [price, setPrice] = useState(0);
  const [timeZone, setTimeZone] = useState("America/Sao_Paulo");
  const [bankInformation, setBankInformation] = useState("");
  const [splitPayment, setSplitPayment] = useState(0);
  const [splitNF, setSplitNF] = useState(false);
  const [status, setStatus] = useState(true);
  const [orderColumn, setOrderColumn] = useState("");
  const [googleCalendarId, setGoogleCalendarId] = useState("");
  const [percentageDiscount, setPercentageDiscount] = useState(0);
  const [recordMeeting, setRecordMeeting] = useState(false);
  const [quickService, setQuickService] = useState(false);

  const [professionals, setProfessionals] = useState([]);
  const [splitAccounts, setSplitAccounts] = useState([]);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [fullScreenEditModal, setFullScreenEditModal] = useState(false);

  const [openPrefessionalPhotoModal, setOpenPrefessionalPhotoModal] =
    useState(false);

  const [openTimesAvaliableModal, setOpenTimesAvaliableModal] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [professionalId, setProfessionalId] = useState("");

  const [professionalServices, setProfessionalServices] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesGroups, setServicesGroups] = useState([]);

  const dayWeek = [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ];

  const [openNewModal, setOpenNewModal] = useState(false);
  const [fullScreenNewModal, setFullScreenNewModal] = useState(false);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const handleSelectionServicesChange = (selected) => {
    setProfessionalServices(selected);
  };

  const handleCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCpf(cpfFormated);
  };

  const getServices = async () => {
    setLoading(true);
    try {
      const responseServices = await api.get("services");
      setServices(responseServices.data);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getServicesGroup = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("services_groups");
      const sortedData = [...data].sort((a, b) => {
        if (a.name === "Planos") return 1;
        if (b.name === "Planos") return -1;
        return 0;
      });
      setServicesGroups(sortedData);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getSplitAccounts = async (userIdSelected) => {
    setLoading(true);
    try {
      const response = await api.get(`/split_accounts/users/${userIdSelected}`);
      setSplitAccounts(response.data);
    } catch (error) {
      setAlertComponent({
        message: "Erro na busca de contas para split de pagamento.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const cpfTratado = cpf.replace(/\D/g, "");
      if (cpfTratado.length !== 11) {
        setAlertModalComponent({
          message: "CPF inválido!",
          type: "error",
        });
        setLoading(false);
        return false;
      }
      const response = await api.get("/users", {
        params: {
          cpf: cpfTratado,
        },
      });
      const userFound = response.data[0];
      if (userFound.type !== 2) {
        setAlertModalComponent({
          message:
            "Usuário não está disponível para ser atribuido a profissional!",
          type: "error",
        });
        setLoading(false);
        return false;
      }
      setUserSelected(userFound);
      await getSplitAccounts(userFound.id);
    } catch (error) {
      setAlertModalComponent({
        message: "Nenhum usuário com esse CPF!",
        type: "error",
      });
    }
    setLoading(false);
    return true;
  };

  const getProfessionals = async () => {
    setLoading(true);
    try {
      const response = await api.get("/professionals");
      setProfessionals(response.data);
    } catch (error) {
      setAlertComponent({
        message: "Nenhum profissional encontrado",
        type: "error",
      });
    }
    setLoading(false);
  };

  const addProfessional = async () => {
    setLoading(true);
    const professionalData = {
      user_id: userSelected.id,
      name: professionalName,
      resume,
      timezone: timeZone,
      bank_information: bankInformation,
      curriculum,
      price,
      status,
      split_payment: splitPayment,
      splitNF,
      calendar_id: googleCalendarId,
      order_column: orderColumn,
      services: professionalServices,
      percentageDiscount,
      needRecord: recordMeeting,
      quickService,
    };
    try {
      await api.post(`/professionals`, professionalData, {
        headers: {
          Accept: "application/json",
        },
      });

      setAlertComponent({
        message: "Profissional criado com sucesso.",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro ao adicionar profissional.",
        type: "error",
      });
    }
    await getProfessionals();
    setLoading(false);
  };

  const clearProfessional = () => {
    setSplitAccounts([]);
    setUserSelected({});
    setCpf("");
    setId("");
    setProfessionalName("");
    setCurriculum("");
    setResume("");
    setPrice(0);
    setTimeZone("America/Sao_Paulo");
    setBankInformation("");
    setSplitPayment(0);
    setSplitNF(false);
    setStatus(true);
    setGoogleCalendarId("");
    setPercentageDiscount(0);
    setProfessionalServices([]);
    setOrderColumn("");
    setRecordMeeting(false);
    setQuickService(false);
  };

  const setProfessionalData = (row) => {
    setUserSelected(row.user);
    setCpf(row.user.cpf);
    setId(row.id);
    setProfessionalName(row.name);
    setCurriculum(row.curriculum);
    setResume(row.resume);
    setBankInformation(row.bank_information);
    setSplitPayment(row.split_payment);
    setSplitNF(row.splitNf);
    setStatus(row.status);
    setOrderColumn(row.order_column);
    setGoogleCalendarId(row.calendar_id);
    setPercentageDiscount(row.percentageDiscount);
    setProfessionalServices(row.services);
    setRecordMeeting(row?.needRecord ?? false);
    setQuickService(row?.quickService ?? false);
  };

  const handleOpenNewModal = () => {
    clearProfessional();
    setOpenNewModal(true);
  };

  const handleOpenEditModal = async (row) => {
    await getSplitAccounts(row.user.id);
    setProfessionalData(row);
    setOpenEditModal(true);
  };

  const handleCloseNewModal = () => {
    clearProfessional();
    setOpenNewModal(false);
  };

  const handleCloseEditModal = () => {
    clearProfessional();
    setOpenEditModal(false);
  };

  const handleCloseProfessionalPhotoModal = () => {
    clearProfessional();
    setOpenPrefessionalPhotoModal(false);
  };

  const handleCloseTimesAvaliableModal = () => {
    setOpenTimesAvaliableModal(false);
    setSchedule([]);
    setProfessionalId("");
  };

  const getProfessionalAvaliableTimes = async (professionalId) => {
    setProfessionalId(professionalId);
    const newTimesAvaliable = [];
    try {
      const responseTimesAvaliable = await api.get(
        `/professionalsSchedulesAvailabilities/${professionalId}`,
      );
      for (let i = 0; i < 7; i++) {
        const timeSelected = responseTimesAvaliable.data.filter(
          (newTime) => newTime.day_week === i,
        );
        newTimesAvaliable.push({
          start_time: timeSelected[0]?.start_time ?? "",
          end_time: timeSelected[0]?.end_time ?? "",
        });
      }
    } catch (error) {
      for (let i = 0; i < 7; i++) {
        newTimesAvaliable.push({
          start_time: "00:00",
          end_time: "00:00",
        });
      }
    } finally {
      setSchedule(newTimesAvaliable);
    }
  };

  const handleTimeAvaliable = (index, event) => {
    const newFormData = [...schedule];
    if (event.target.value.length > 5) {
      return;
    }
    newFormData[index][event.target.name] = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{2})$/, "$1:$2");
    setSchedule(newFormData);
  };

  const handleOpenTimesAvaliableModal = (row) => {
    getProfessionalAvaliableTimes(row.id);
    setOpenTimesAvaliableModal(true);
  };

  const updateTimesAvaliable = async () => {
    const schedulesStrutured = schedule.map((selected, index) => ({
      professional_id: professionalId,
      day_week: index,
      start_time: selected.start_time,
      end_time: selected.end_time,
    }));
    try {
      schedulesStrutured.forEach(async (schedulestrutured) => {
        await api.post(
          `/professionalsSchedulesAvailabilities`,
          schedulestrutured,
        );
      });
      setAlertComponent({
        message: "Disponibilidades atualizada com sucesso!",
        type: "success",
      });
      handleCloseTimesAvaliableModal();
    } catch (err) {
      setAlertComponent({
        message: "Errro na atualização dos horários do profissional.",
        type: "error",
      });
    }
  };

  const updateProfessional = async () => {
    setLoading(true);
    const professionalData = {
      user_id: userSelected.id,
      name: professionalName,
      resume,
      timezone: timeZone,
      bank_information: bankInformation,
      curriculum,
      price,
      status,
      split_payment: splitPayment,
      splitNF,
      calendar_id: googleCalendarId,
      order_column: orderColumn,
      services: professionalServices,
      percentageDiscount,
      needRecord: recordMeeting,
      quickService,
    };
    try {
      await api.put(`/professionals/${id}`, professionalData, {
        headers: {
          Accept: "application/json",
        },
      });

      setAlertComponent({
        message: "Profissional atualizado com sucesso.",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na atualização do profissional.",
        type: "error",
      });
    }
    await getProfessionals();
    handleCloseEditModal();
    setLoading(false);
  };

  const updateProfessionalPhoto = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("photo", photo);
    try {
      await api.put(`/professionals/${id}/photo`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      handleCloseProfessionalPhotoModal();
      setAlertComponent({
        message: "Foto do profissional enviada com sucesso.",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na atualização do profissional.",
        type: "error",
      });
    }
    await getProfessionals();
    handleCloseEditModal();
    setLoading(false);
  };

  const handleOpenPrefessionalPhoto = async (row) => {
    await setProfessionalData(row);
    setOpenPrefessionalPhotoModal(true);
  };

  const extractGroups = (params) => {
    let profGroups = "";
    params.row.services.forEach((service) => {
      if (profGroups !== "") {
        profGroups += ", ";
      }
      profGroups += `${service.group_name}`;
    });
    return <span>{profGroups}</span>;
  };

  const extractStatus = (params) => {
    if (params.row.status) {
      return <span>Ativo</span>;
    }
    return <span>Desativado</span>;
  };

  const columns = [
    {
      field: "order_column",
      headerName: "Ordem",
      flex: 0.2,
      headerClassName: "dataGrid-header",
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1.5,
      headerClassName: "dataGrid-header",
    },
    {
      field: "groups",
      headerName: "Grupos",
      flex: 1.5,
      headerClassName: "dataGrid-header",
      renderCell: extractGroups,
    },
    {
      field: "status",
      headerName: "Ativo",
      flex: 0.8,
      headerClassName: "dataGrid-header",
      renderCell: extractStatus,
    },
    {
      field: "actions",
      headerName: "Operações",
      flex: 1,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenEditModal(row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            size="large"
            onClick={() => handleOpenTimesAvaliableModal(row)}
          >
            <ScheduleIcon />
          </IconButton>
          <IconButton
            size="large"
            onClick={() => handleOpenPrefessionalPhoto(row)}
          >
            <PartyModeIcon />
          </IconButton>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    async function getInfos() {
      if (!authenticatedUser) {
        window.location.replace("/login");
      }
      if (professionals.length === 0) {
        await getProfessionals();
      }
      if (services.length === 0) {
        await getServices();
      }
      if (servicesGroups.length === 0) {
        await getServicesGroup();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertModalComponent.message !== "") {
      setTimeout(() => {
        setAlertModalComponent({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertModalComponent]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      {/* Modal de Criação de Profissional */}
      <Dialog
        open={openNewModal}
        onClose={handleCloseNewModal}
        fullScreen={fullScreenNewModal}
        scroll="body"
        aria-labelledby="create-professional-title"
        aria-describedby="create-professional-description"
        maxWidth="xxl"
        fullWidth
      >
        <DialogTitle id="create-professional-title" style={styles.dialogTitle}>
          <div>
            <h3>Criação de Profissional</h3>
          </div>
          <div>
            {!fullScreenEditModal ? (
              <IconButton onClick={() => setFullScreenNewModal(true)}>
                <FullscreenIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => setFullScreenNewModal(false)}>
                <FullscreenExitIcon />
              </IconButton>
            )}
            <IconButton onClick={handleCloseNewModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {services ? (
            <Grid container>
              {alertModalComponent.message && (
                <Grid item xs={12} px={2}>
                  <MKAlert color={alertModalComponent.type}>
                    {alertModalComponent.message}
                  </MKAlert>
                </Grid>
              )}
              <Grid item xs={12} md={6} px={2} mb={1} pt={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      variant="outlined"
                      label="CPF"
                      name="cpf"
                      value={cpf}
                      onChange={(e) => handleCPF(e.target.value)}
                      onBlur={getUser}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      variant="outlined"
                      label="Usuário"
                      name="userName"
                      value={userSelected?.name || ""}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      variant="outlined"
                      label="Ordem de Exibição"
                      name="orderColumn"
                      value={orderColumn}
                      onChange={(e) => setOrderColumn(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      variant="outlined"
                      label="Nome"
                      name="professionalName"
                      value={professionalName}
                      onChange={(e) => setProfessionalName(e.target.value)}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      variant="outlined"
                      label="Descrição"
                      name="resume"
                      value={resume}
                      onChange={(e) => setResume(e.target.value)}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      variant="outlined"
                      label="Currículo"
                      name="curriculum"
                      value={curriculum}
                      onChange={(e) => setCurriculum(e.target.value)}
                      multiline
                      rows={10}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <MKInput
                      variant="outlined"
                      label="Google Calendar Id"
                      name="googleCalendarId"
                      value={googleCalendarId}
                      onChange={(e) => setGoogleCalendarId(e.target.value)}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <MKInput
                      variant="outlined"
                      label="Desconto Planos (%)"
                      type="number"
                      name="percentageDiscount"
                      value={percentageDiscount}
                      onChange={(e) => setPercentageDiscount(+e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2">
                      Split de Pagamentos:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Select
                      variant="outlined"
                      id="bankInformation"
                      value={bankInformation ?? ""}
                      sx={{ height: "45px" }}
                      onChange={(e) => setBankInformation(e.target.value)}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem disabled value="">
                        Conta de Recebimento
                      </MenuItem>
                      {splitAccounts.length > 0 &&
                        splitAccounts.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            selected={item.id === bankInformation}
                          >
                            {item.bankAccountHolderName}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <MKInput
                      variant="outlined"
                      label="SPLIT (%)"
                      type="number"
                      name="splitPayment"
                      value={splitPayment}
                      onChange={(e) => setSplitPayment(+e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Select
                      variant="outlined"
                      value={splitNF}
                      sx={{ height: "45px" }}
                      onChange={(e) => setSplitNF(e.target.value)}
                      disabled={splitPayment === 0}
                      fullWidth
                    >
                      <MenuItem value selected={splitNF}>
                        NF Parcial
                      </MenuItem>
                      <MenuItem value={false} selected={!splitNF}>
                        NF Total
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Switch
                      checked={!!status}
                      onChange={() => setStatus(!status)}
                    />
                    <MKTypography
                      variant="body2"
                      sx={{
                        color: status ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      Profissional {status ? "ATIVO" : "INATIVO"}
                    </MKTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} px={2} md={6} mb={1} py={1}>
                <Grid item xs={12} md={12}>
                  <ProfessionalCheckbox
                    groups={servicesGroups}
                    services={services}
                    professionalServices={professionalServices}
                    onSelectionChange={handleSelectionServicesChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} px={2} mb={1} py={1}>
                <MKBox width="100%">
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={addProfessional}
                    fullWidth
                  >
                    Gravar Dados
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Typography>Nenhum Profissional cadastrado!</Typography>
            </Grid>
          )}
        </DialogContent>
      </Dialog>

      {/* Modal de Alteração de Profissional */}
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        fullScreen={fullScreenEditModal}
        scroll="body"
        aria-labelledby="update-professional-title"
        aria-describedby="update-professional-description"
        maxWidth="xxl"
        fullWidth
      >
        <DialogTitle id="update-professional-title" style={styles.dialogTitle}>
          <div>
            <h3>Edição de Profissional</h3>
          </div>
          <div>
            {!fullScreenEditModal ? (
              <IconButton onClick={() => setFullScreenEditModal(true)}>
                <FullscreenIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => setFullScreenEditModal(false)}>
                <FullscreenExitIcon />
              </IconButton>
            )}
            <IconButton onClick={handleCloseEditModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            {alertModalComponent.message && (
              <Grid item xs={12} px={2}>
                <MKAlert color={alertModalComponent.type}>
                  {alertModalComponent.message}
                </MKAlert>
              </Grid>
            )}
            <Grid item xs={12} md={6} px={2} mb={1} pt={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <MKInput
                    variant="outlined"
                    label="CPF"
                    name="cpf"
                    value={cpf}
                    onChange={(e) => handleCPF(e.target.value)}
                    onBlur={getUser}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKInput
                    variant="outlined"
                    label="Usuário"
                    name="userName"
                    value={userSelected?.name || ""}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKInput
                    variant="outlined"
                    label="Ordem de Exibição"
                    name="orderColumn"
                    value={orderColumn}
                    onChange={(e) => setOrderColumn(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKInput
                    variant="outlined"
                    label="Nome"
                    name="professionalName"
                    value={professionalName}
                    onChange={(e) => setProfessionalName(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKInput
                    variant="outlined"
                    label="Descrição"
                    name="resume"
                    value={resume}
                    onChange={(e) => setResume(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MKInput
                    variant="outlined"
                    label="Currículo"
                    name="curriculum"
                    value={curriculum}
                    onChange={(e) => setCurriculum(e.target.value)}
                    multiline
                    rows={8}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={8} md={8}>
                  <MKInput
                    variant="outlined"
                    label="Google Calendar Id"
                    name="googleCalendarId"
                    value={googleCalendarId}
                    onChange={(e) => setGoogleCalendarId(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <MKInput
                    variant="outlined"
                    label="Desconto Planos (%)"
                    type="number"
                    name="percentageDiscount"
                    value={percentageDiscount}
                    onChange={(e) => setPercentageDiscount(+e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Select
                    variant="outlined"
                    id="bankInformation"
                    value={bankInformation ?? ""}
                    sx={{ height: "45px" }}
                    onChange={(e) => setBankInformation(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem disabled value="">
                      Conta de Recebimento
                    </MenuItem>
                    {splitAccounts.length > 0 &&
                      splitAccounts.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          selected={item.id === bankInformation}
                        >
                          {item.bankAccountHolderName}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item xs={2} md={2}>
                  <MKInput
                    variant="outlined"
                    label="SPLIT (%)"
                    type="number"
                    name="splitPayment"
                    value={splitPayment}
                    onChange={(e) => setSplitPayment(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <Select
                    variant="outlined"
                    value={splitNF}
                    sx={{ height: "45px" }}
                    onChange={(e) => setSplitNF(e.target.value)}
                    disabled={splitPayment === 0}
                    fullWidth
                  >
                    <MenuItem value selected={splitNF}>
                      NF Parcial
                    </MenuItem>
                    <MenuItem value={false} selected={!splitNF}>
                      NF Total
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Switch
                    checked={!!status}
                    onChange={() => setStatus(!status)}
                  />
                  <MKTypography
                    variant="body2"
                    sx={{
                      color: status ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    Cadastro {status ? "ATIVO" : "INATIVO"}
                  </MKTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Switch
                    checked={!!recordMeeting}
                    onChange={() => setRecordMeeting(!recordMeeting)}
                  />
                  <MKTypography
                    variant="body2"
                    sx={{
                      color: recordMeeting ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    Gravação
                  </MKTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Switch
                    checked={!!quickService}
                    onChange={() => setQuickService(!quickService)}
                  />
                  <MKTypography
                    variant="body2"
                    sx={{
                      color: quickService ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    Atendimento Rápido
                  </MKTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} px={2} md={6} mb={1} py={1}>
              <Grid item xs={12} md={12}>
                <ProfessionalCheckbox
                  groups={servicesGroups}
                  services={services}
                  professionalServices={professionalServices}
                  onSelectionChange={handleSelectionServicesChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} px={2} mb={1} py={1}>
              <MKButton
                variant="gradient"
                color="info"
                onClick={updateProfessional}
                fullWidth
              >
                Gravar Dados
              </MKButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Modal de Alteração de Horários */}
      <Dialog
        open={openTimesAvaliableModal}
        onClose={handleCloseTimesAvaliableModal}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="times-avaliable-title" style={styles.dialogTitle}>
          <div>
            <h3>Disponibilidade de horários</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseTimesAvaliableModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} md={12}>
              {schedule &&
                schedule.map((timesAvaliable, index) => (
                  <MKBox key={index} sx={{ display: "flex" }}>
                    <MKBox width="20%" textAlign="right" marginX="10px">
                      <MKTypography variant="body2">
                        {dayWeek[index]}
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      marginX="10px"
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        p: "2px",
                      }}
                    >
                      <MKInput
                        type="text"
                        name="start_time"
                        value={timesAvaliable.start_time}
                        onChange={(event) => handleTimeAvaliable(index, event)}
                        fullWidth
                        maxwidth={5}
                      />
                    </MKBox>
                    <MKBox marginX="10px" sx={{ display: "flex" }}>
                      <MKInput
                        type="text"
                        name="end_time"
                        value={timesAvaliable.end_time}
                        onChange={(event) => handleTimeAvaliable(index, event)}
                        fullWidth
                        maxwidth={5}
                      />
                    </MKBox>
                  </MKBox>
                ))}
            </Grid>
          </Grid>
          <MKBox>
            <MKBox mx={2} mb={1} pt={1}>
              <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                <MKBox width="100%" p={2}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={updateTimesAvaliable}
                    fullWidth
                  >
                    Gravar Dados
                  </MKButton>
                </MKBox>
              </Grid>
            </MKBox>
          </MKBox>
        </DialogContent>
      </Dialog>

      {/* Modal de Alteração de Foto */}
      <Dialog
        open={openPrefessionalPhotoModal}
        onClose={handleCloseProfessionalPhotoModal}
        scroll="body"
        aria-labelledby="professional-photo-title"
        aria-describedby="professional-photo-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="professional-photo-title" style={styles.dialogTitle}>
          <div>
            <h3>Envio de foto para profissional</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseProfessionalPhotoModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} md={12} textAlign="center">
              <MKBox
                component="img"
                src={`https://storage.googleapis.com/mpo_images/professionals/photos/${id}.jpeg`}
                alt={professionalName}
                width="50%"
                borderRadius="md"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MKTypography variant="h4" textAlign="center">
                Envie um arquivo <b>APENAS</b> com extensão <b>JPEG</b>
              </MKTypography>
              <MKInput
                variant="outlined"
                name="photo"
                id="photo"
                type="file"
                onChange={(e) => setPhoto(e.target.files[0])}
                fullWidth
                required
                sx={{ py: 2 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <MKButton
              variant="gradient"
              color="info"
              onClick={updateProfessionalPhoto}
              fullWidth
            >
              Gravar Dados
            </MKButton>
          </Grid>
        </DialogContent>
      </Dialog>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        component="section"
        position="relative"
        py={20}
        width="100%"
        height="100vh"
      >
        <Grid
          container
          alignItems="top"
          sx={{ display: "flex", alignContent: "stretch" }}
        >
          <Grid item xs={12} lg={12} py={3} pl={{ xl: 1, lg: 0 }}>
            <MKBox
              sx={{
                height: "80vh",
                width: "100%",
                paddingX: "10%",
                margin: "10px",
                textAlign: "center",
                "& .dataGrid-header": {
                  backgroundColor: "#00bbd4",
                  color: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {alertComponent.message && (
                <Grid item xs={12}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              {professionals && (
                <DataGrid
                  rows={professionals}
                  columns={columns}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              )}
            </MKBox>
            <Fab
              sx={{ position: "absolute", bottom: 50, left: 30 }}
              color="success"
              aria-label="add"
              onClick={() => handleOpenNewModal()}
            >
              <AddIcon color="white" />
            </Fab>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default ProfessionalAdminPanel;
