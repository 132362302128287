import React, { useEffect, useState } from "react";
import OtpInput from "react18-input-otp";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import { api } from "../../lib/axios";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");

function DocumentSignature(params) {
  const { appointmentInfo, setSignatureModal, closeSignatureModal } = params;

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  const [otpCode, setOtpCode] = useState("");

  const handleSignatureDocument = async () => {
    if (!appointmentInfo) {
      setAlertMessage({
        message: "Informações de atendimento não encontrado.",
        type: "error",
      });
      return;
    }
    if (otpCode === "" || otpCode.length < 6) {
      setAlertMessage({
        message: "Informe o código de validação.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      setAlertMessage({
        message: "Assinando documento... Aguarde!",
        type: "success",
      });
      const response = await api.put(`/schedules/${appointmentInfo.id}/sign`, {
        temporaryCode: otpCode,
      });
      if (response.status >= 400) {
        setAlertMessage({
          message: "Erro ao assinar documento",
          type: "error",
        });
        return;
      }
      setAlertMessage({
        message: "Documento assinado com sucesso",
        type: "success",
      });
      setOtpCode("");
      setSignatureModal(false);
      closeSignatureModal();
    } catch (err) {
      setAlertMessage({
        message: "Erro ao assinar documento",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (alertMessage.message !== "") {
      setTimeout(() => {
        setAlertMessage({
          type: "",
          message: "",
        });
      }, 10000);
    }
  }, [alertMessage]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={2}>
          {alertMessage.message !== "" && (
            <Grid item xs={12} md={12}>
              <Alert
                severity={alertMessage.type}
                sx={{ marginX: "20px", marginY: "10px" }}
              >
                {alertMessage.message}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              {appointmentInfo && (
                <>
                  <Typography variant="body2">
                    Paciente: {appointmentInfo?.user?.name}
                  </Typography>
                  <Typography variant="body2">
                    Data/Hora:{" "}
                    {dayjs
                      .tz(appointmentInfo.date_start, "America/Sao_Paulo")
                      .format("DD/MM/YYYY [às] HH:mm")}
                  </Typography>
                  <Typography variant="body2">
                    Serviço: {appointmentInfo.service.name}
                  </Typography>
                  <Typography variant="body2">
                    Status: {appointmentInfo.status}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel htmlFor="otpCode" sx={{ textAlign: "center" }}>
              Código de Validação
            </InputLabel>
            <OtpInput
              id="otpCode"
              numInputs={6}
              value={otpCode}
              onChange={(value) => setOtpCode(value)}
              inputStyle={{
                width: "100%",
                height: "80px",
                margin: "5px",
                fontSize: "20px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSignatureDocument}
              disabled={otpCode.length < 6}
            >
              <Typography variant="buttom" sx={{ color: "#ffffff" }}>
                Assinar Documento
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DocumentSignature;
