import React from "react";
import routes from "routes";
import { useHistory } from "react-router-dom";
import footerRoutes from "footer.routes";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import bgImage from "assets/images/bg-home.jpg";
import LogoMPO from "assets/images/logo_horizontal.png";

import reproduzirVideo from "assets/videos/baseVideoPlayer.jpg";

import MKBox from "components/MKBox";
import Menu from "components/Menu";
import DefaultFooter from "components/Footers/DefaultFooter";

import CarouselPsychiatrist from "pages/Home/sections/CarouselPsychiatrist";
import Logos from "pages/Home/sections/Logos";
import QuestionsCarroussel from "pages/Home/sections/Questions";
import Scheduling from "pages/Home/sections/Scheduling";
import AppLinks from "./sections/AppsLinks";
import Testimonials from "./sections/Testimonials";
import BannerMPO from "./sections/BannerMPO";
// import StoreFeature from "./sections/StoreFeature";

function Home() {
  const history = useHistory();
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <MKBox
        minHeight={{ xs: "70vh", md: "80vh" }}
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="left" mx="auto" />
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 0, lg: 0 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Scheduling />
      </Card>
      <Divider />
      <BannerMPO />
      <Divider />
      <AppLinks />
      <CarouselPsychiatrist />
      <Grid container>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            marginX: { xs: 3, md: "10%" },
            paddingY: 3,
          }}
        >
          <CardMedia
            component="img"
            image={reproduzirVideo}
            alt="Reproduzir Video"
            onClick={() => history.push("/player-video")}
            sx={{
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>

      <Divider />
      <QuestionsCarroussel />
      {/* <StoreFeature /> */}
      <Divider />
      <Testimonials />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: "#7b809a",
          marginY: 3,
        }}
      >
        <Typography
          variant="body1"
          align="center"
          sx={{ marginY: 3, color: "#ffffff" }}
        >
          Não temos atendimento presencial
        </Typography>
      </Box>
      <Logos />
      <MKBox
        lg={6}
        sm={12}
        variant="gradient"
        bgColor="info"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          padding: 3,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#fff",
          }}
        >
          ATENÇÃO: Este site não oferece tratamento ou aconselhamento imediato
          para pessoas em crise suicida. Em caso de crise, ligue para 188 (CVV)
          ou acesse o site www.cvv.org.br. Em caso de emergência, procure
          atendimento em um hospital mais próximo.
        </Typography>
      </MKBox>
      <MKBox sx={{ margin: 2 }}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
