/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import routes from "routes";

// @mui material components
import {
  Card,
  Container,
  CircularProgress,
  Grid,
  TextField,
  Backdrop,
  Box,
  Typography,
} from "@mui/material";

// Otis Kit PRO components
import Menu from "components/Menu";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../lib/axios";

function MedicalRecords() {
  const { id } = useParams();

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  if (!authenticatedUser) {
    window.location.replace("/login");
  }

  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);
  const [appointmentsDetails, setAppointmentsDetails] = useState(null);

  const [name, setName] = useState();
  const [medicalRecords, setMedicalRecords] = useState("");

  const saveMedicalRecords = async () => {
    setLoading(true);
    try {
      const response = await api.post("/medicalRecords", {
        schedule_id: appointmentsDetails.id,
        report: medicalRecords,
      });
      const appointmentExtract = response.data.filter((res) => res.id === id);

      setAppointmentsDetails(appointmentExtract);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getAppointment = async () => {
    setLoading(true);
    try {
      const response = await api.get("/schedules");
      const appointmentExtract = response.data.filter((res) => res.id === id);

      setAppointmentsDetails(appointmentExtract[0]);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!appointmentsDetails) {
      getAppointment();
    }
  }, []);

  useEffect(() => {
    if (
      appointmentsDetails &&
      authenticatedUser.professional.id &&
      authenticatedUser.professional.id ===
        appointmentsDetails.professional.id &&
      appointmentsDetails.status === "AGENDADO"
    ) {
      window.addEventListener("beforeunload", (event) => {
        saveMedicalRecords();
        event.returnValue = "Você realmente quer sair?";
      });
      setName(appointmentsDetails.user.name);
    }
  }, [appointmentsDetails]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={16}
          width="100%"
          height="100vh"
        >
          <Grid container alignItems="top" textAlign="center">
            <Grid item xs={12} lg={12} py={4}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={1}
                  mt={-3}
                  px={3}
                  py={1}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography variant="h5" fontWeight="light" color="white">
                    PRONTUÁRIO ELETRÔNICO
                  </MKTypography>
                  <MKTypography variant="h5" fontWeight="light" color="white">
                    {name}
                  </MKTypography>
                </MKBox>
                <Grid item xs={12} lg={12} pb={1}>
                  <Grid container textAlign="center" px={1} height="auto">
                    <MKTypography variant="button" width="100%">
                      Utilize o padrão: Queixa Principal / HPP / Conduta
                    </MKTypography>
                    <Grid item xs={12} height="auto">
                      <TextField
                        label="Prontuário"
                        multiline
                        fullWidth
                        minRows={25}
                        maxRows={30}
                        value={medicalRecords}
                        onChange={(e) => setMedicalRecords(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} pt={2}>
                      <MKButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={() => saveMedicalRecords()}
                      >
                        GRAVAR
                      </MKButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </>
  );
}

export default MedicalRecords;
