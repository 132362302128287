/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Divider } from "@mui/material";

import instagram from "assets/instagram.png";
import facebook from "assets/facebook.png";

function DefaultFooter({ content }) {
  const { brand, menus, copyright } = content;

  return (
    <MKBox component="footer" sx={{ mx: "5%" }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Divider
            xs={12}
            md={0}
            sx={{ display: { xs: "block", md: "none" } }}
          />
          <MKBox textAlign="center">
            <Link to={brand.route}>
              <MKBox
                component="img"
                src={brand.image}
                alt={brand.name}
                minWidth="10rem"
                maxWidth="20rem"
              />
            </Link>
          </MKBox>
          <Divider
            xs={12}
            md={0}
            sx={{ display: { xs: "block", md: "none" } }}
          />
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              paddingX: 3,
            }}
          >
            <MKBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                paddingX: 3,
                cursor: "pointer",
              }}
              onClick={() =>
                window.open("https://www.instagram.com/meupsiquiatraonline/")
              }
            >
              <MKBox
                src={instagram}
                alt="Instagram"
                component="img"
                sx={{ width: "2rem", height: "2rem" }}
              />
              <MKTypography variant="caption">
                @meupsiquiatraonline
              </MKTypography>
            </MKBox>
            <MKBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                paddingX: 3,
                cursor: "pointer",
              }}
              onClick={() =>
                window.open("https://www.facebook.com/meupsiquiatraonline")
              }
            >
              <MKBox
                src={facebook}
                alt="Facebook"
                component="img"
                sx={{ width: "2rem", height: "2rem" }}
              />
              <MKTypography variant="caption">
                /meupsiquiatraonline
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
        <Grid item xs={12} md={3}>
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              paddingX: 5,
            }}
          >
            <MKTypography
              variant="body2"
              sx={{ textAlign: "center", lineHeight: 1.5 }}
            >
              Responsável Técnico
            </MKTypography>
            <MKTypography
              variant="body1"
              sx={{
                textAlign: "center",
                fontWeight: "700",
                lineHeight: 1.2,
              }}
            >
              Dr. Elias Junior
            </MKTypography>
            <MKTypography variant="caption" sx={{ textAlign: "center" }}>
              MÉDICO
            </MKTypography>
            <MKTypography variant="caption" sx={{ textAlign: "center" }}>
              CRM/RS 46.952
            </MKTypography>
          </MKBox>
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              paddingX: 3,
            }}
          >
            <MKTypography
              variant="caption"
              sx={{ textAlign: "center", lineHeight: 1.5 }}
            >
              Registro da empresa no conselho regional de medicinal do Rio
              Grande do Sul - CRM-RS 14547
            </MKTypography>
          </MKBox>

          <Divider
            xs={12}
            md={0}
            sx={{ display: { xs: "block", md: "none" } }}
          />
        </Grid>
        {menus.map(({ name: title, items }) => (
          <Grid
            item
            key={title}
            xs={12}
            md={3}
            sx={{ mb: 2, textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              {title}
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              {items.map(({ name, route, href }) => (
                <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                  {href ? (
                    <MKTypography
                      component="a"
                      href={href}
                      target="_self"
                      rel="noreferrer"
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                    >
                      {name}
                    </MKTypography>
                  ) : (
                    <MKTypography
                      component={Link}
                      to={route}
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                    >
                      {name}
                    </MKTypography>
                  )}
                </MKBox>
              ))}
            </MKBox>
          </Grid>
        ))}
        <Divider />
        <Grid item xs={12} sx={{ textAlign: "center" }} lineHeight={1}>
          <MKTypography variant="caption">
            MPO MEU PSIQUIATRA ON LINE LTDA - CNPJ: 45.544.275/0001-24 - Rua
            Tapuias, 372 - Centro - CEP: 98.500.000 - Tenente Portela-RS
            <br />
          </MKTypography>
          <MKTypography variant="caption">
            MPO SERVIÇOS LTDA - CNPJ: 45.544.275/0002-05 - Avenida Paulista,
            1471 - Conj 511 - Cerqueira César - Edifício Barão de Cristina -
            Bairro Bela Vista - CEP: 01.311-927 - São Paulo-SP
            <br />
          </MKTypography>
          {copyright}
        </Grid>
      </Grid>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
};

export default DefaultFooter;
