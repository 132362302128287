/* eslint-disable no-alert */
import React, { useContext, useEffect, useState } from "react";
import { Context } from "Context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import routes from "routes";
import * as jose from "jose";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

// @mui material components
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import DocumentSignature from "components/DocumentSignature";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DrawIcon from "@mui/icons-material/Draw";

import { api } from "../../lib/axios";

const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
  ],
};

const formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

function ProfessionalAppointments() {
  const history = useHistory();
  const { handleLogout } = useContext(Context);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  if (!authenticatedUser) {
    window.location.replace("/login");
  }
  const routeIndex = authenticatedUser.type ?? "public";

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [alertModalInfos, setAlertModalInfos] = useState({
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState(null);
  const [confirmedAppointments, setConfirmedAppointments] = useState(null);

  const [professional, setProfessional] = useState({});
  const [userSplitAccount, setUserSplitAccount] = useState({});

  const [scheduleInfo, setScheduleInfo] = useState({});
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileTypeCustom, setFileTypeCustom] = useState("");

  const [signatureModal, setSignatureModal] = useState(false);
  const [medicalRecordModal, setMedicalRecordModal] = useState(false);
  const [appointmentSelected, setAppointmentSelected] = useState(null);
  const [medicalRecord, setMedicalRecord] = useState("");

  const closeMedicalRecordModal = () => {
    setMedicalRecordModal(false);
    setAppointmentSelected(null);
    setMedicalRecord("");
  };

  const openMedicalRecordModal = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/schedules/${id}`);
      setAppointmentSelected(data);
      setMedicalRecord(data?.report || "");
      setMedicalRecordModal(true);
    } catch (error) {
      setAlertComponent({
        type: "error",
        message: "Erro ao buscar agendamento",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateMedicalRecord = async () => {
    if (!appointmentSelected) {
      setAlertComponent({
        type: "error",
        message: "Selecione um agendamento",
      });
      return;
    }
    setLoading(true);
    try {
      await api.post("/medicalRecords", {
        schedule_id: appointmentSelected.id,
        report: medicalRecord,
      });
      setAlertComponent({
        type: "success",
        message: "Prontuário atualizado com sucesso",
      });
    } catch (error) {
      setAlertComponent({
        type: "error",
        message: "Erro ao atualizar prontuário",
      });
    } finally {
      setLoading(false);
      setMedicalRecordModal(false);
    }
  };

  const closeSignatureModal = () => {
    setSignatureModal(false);
    setAppointmentSelected(null);
  };

  const getAppointments = async () => {
    setLoading(true);
    try {
      const response = await api.get("/schedules", {
        params: {
          professional_id: authenticatedUser.professional.id,
          status: ["AGENDADO", "AVALIAÇÃO", "FINALIZADO"],
        },
      });
      const professionalAppointments = response.data;
      professionalAppointments.sort(
        (a, b) => new Date(b.date_start) - new Date(a.date_start),
      );
      setAppointments(professionalAppointments);
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Não foi possível carregar os agendamentos.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getUserSplitAccount = async (splitId) => {
    setLoading(true);
    try {
      const response = await api.get(`/split_accounts/info/${splitId}`);
      setUserSplitAccount(response.data);
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Não foi possível carregar a conta split.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getProfessional = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/professionals/${authenticatedUser.professional.id}`,
      );
      setProfessional(response.data);
    } catch (err) {
      setAlertComponent({
        message:
          err.response?.data?.message ||
          "Não foi possível carregar os dados do profissional.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleOpenUploadModal = (row) => {
    setScheduleInfo(row);
    setUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setUploadModal(false);
    setScheduleInfo({});
    setFile(null);
    setFileType("");
    setFileTypeCustom("");
  };

  const uploadNewFile = async () => {
    if (Object.keys(scheduleInfo).length === 0) {
      setAlertComponent({
        type: "error",
        message: "Agendamento não encontrado.",
      });
      setUploadModal(false);
      return;
    }

    if (!file) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o arquivo que deseja enviar",
      });
      return;
    }

    if (!fileType) {
      setAlertModalInfos({
        type: "error",
        message: "Selecione o tipo do arquivo",
      });
      return;
    }

    if (fileType === "custom" && fileTypeCustom === "") {
      setAlertModalInfos({
        type: "error",
        message: "Informe o tipo do arquivo no campo de texto",
      });
      return;
    }

    let description = "";
    if (fileType === "custom") {
      description = fileTypeCustom;
    } else {
      description = fileType;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("schedule_id", scheduleInfo.id);
      formData.append("description", description);

      await api.post(`/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleCloseUploadModal();
      setAlertComponent({
        type: "success",
        message: "Arquivo salvo com sucesso",
      });
    } catch (error) {
      handleCloseUploadModal();
      setAlertComponent({
        type: "error",
        message: "Erro ao salvar arquivo",
      });
    }
    setLoading(false);
  };

  const extractDate = (params) =>
    dayjs(params.row.date_start)
      .add(3, "hours")
      .tz("America/Sao_Paulo")
      .format("DD/MM/YYYY [às] HH:mm");

  const columnsAppointments = [
    { field: "data", headerName: "Data", flex: 0.8, valueGetter: extractDate },
    {
      field: "paciente",
      headerName: "Paciente",
      flex: 1,
      valueGetter: (params) => params.row.user.name,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
    {
      field: "reportSigned",
      headerName: "Prontuário",
      flex: 0.5,
      valueGetter: (params) =>
        params.row?.reportSigned ? "Assinado" : "PENDENTE",
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.8,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <Tooltip title="Detalhes do Agendamento" placement="top">
            <IconButton
              size="large"
              onClick={() =>
                history.push(`professional-appointment-details/${row.id}`)
              }
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          {!["PENDENTE", "CANCELADO"].includes(row.status) && (
            <>
              <Tooltip title="Enviar arquivo" placement="top">
                <IconButton
                  size="large"
                  onClick={() => handleOpenUploadModal(row)}
                >
                  <CloudUploadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar Prontuário" placement="top">
                <IconButton
                  size="large"
                  onClick={() => openMedicalRecordModal(row.id)}
                >
                  <EditNoteIcon />
                </IconButton>
              </Tooltip>
              {["FINALIZADO", "AVALIAÇÃO"].includes(row.status) &&
                professional?.certificateId &&
                !row?.reportSigned && (
                  <Tooltip title="Assinar Prontuário" placement="top">
                    <IconButton
                      size="large"
                      onClick={() => {
                        setAppointmentSelected(row);
                        setSignatureModal(true);
                      }}
                    >
                      <DrawIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </>
          )}
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    async function getInfos() {
      if (
        professional?.bank_information &&
        Object.keys(userSplitAccount).length === 0
      ) {
        await getUserSplitAccount(professional.bank_information);
      }
    }
    getInfos();
  }, [professional]);

  useEffect(() => {
    if (appointments) {
      setConfirmedAppointments(
        appointments.filter((appointment) => appointment.status === "AGENDADO"),
      );
    }
  }, [appointments]);

  useEffect(() => {
    const decodedToken = jose.decodeJwt(localStorage.getItem("token"));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (
      authenticatedUser.type !== "professional" ||
      decodedToken.exp <= currentTimestamp
    ) {
      handleLogout();
      window.location.replace("/login");
    }

    async function getInfos() {
      if (!appointments) {
        await getAppointments();
      }
      if (Object.keys(professional).length === 0) {
        await getProfessional();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertModalInfos.message !== "") {
      setTimeout(() => {
        setAlertModalInfos({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertModalInfos]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={20}
          width="100%"
          height="100vh"
        >
          {alertComponent.message && (
            <Box
              sx={{ position: "fixed", bottom: 1, right: 10, zIndex: 50000 }}
            >
              <MKAlert color={alertComponent.type}>
                {alertComponent.message}
              </MKAlert>
            </Box>
          )}
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={1}
              mb={1}
              textAlign="center"
            >
              <MKTypography
                variant="body1"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Próximos Agendamentos
              </MKTypography>
            </MKBox>
            {professional?.quickService && (
              <MKBox mx={2} mb={1} height="auto">
                <MKButton
                  variant="gradient"
                  color="warning"
                  component={Link}
                  to="/professional-quick-services"
                  size="large"
                  fullWidth
                >
                  ACESSAR SALA DE ATENDIMENTOS RÁPIDOS
                </MKButton>
              </MKBox>
            )}
            <MKBox mx={2} mb={1} height="auto">
              <MKTypography variant="body2" fontWeight="medium" pt={2}>
                Confirmados:
              </MKTypography>
              {confirmedAppointments && confirmedAppointments.length > 0 && (
                <Grid>
                  {confirmedAppointments.map((appointment) => (
                    <Box py={0.3} key={appointment.id}>
                      <MKButton
                        variant="gradient"
                        color="success"
                        component={Link}
                        to={`/professional-appointment-details/${appointment.id}`}
                        size="large"
                        fullWidth
                      >
                        {dayjs(appointment.date_start)
                          .add(3, "hours")
                          .tz("America/Sao_Paulo")
                          .format("dddd DD/MM/YYYY [às] HH:mm")}{" "}
                        - {appointment.user.name}
                      </MKButton>
                    </Box>
                  ))}
                </Grid>
              )}
            </MKBox>
            <MKBox width="100%" py={2}>
              <MKTypography variant="body2" align="center">
                Clique no agendamento para abrir a tela com os detalhes.
              </MKTypography>
            </MKBox>
          </Card>
          <Divider />
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={1}
              mb={1}
              textAlign="center"
            >
              <MKTypography
                variant="body1"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Histórico de Agendamentos
              </MKTypography>
            </MKBox>
            {appointments?.length > 0 && (
              <MKBox
                mx={2}
                mb={1}
                py={1}
                sx={{
                  "& .row-pending": {
                    backgroundColor: "#fdfdeb",
                  },
                }}
              >
                <DataGrid
                  rows={appointments}
                  columns={columnsAppointments}
                  pageSize={100}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  getCellClassName={(params) => {
                    if (!params.row?.reportSigned) {
                      return "row-pending";
                    }
                    return "";
                  }}
                />
              </MKBox>
            )}
          </Card>
          <Divider />
          {userSplitAccount && Object.keys(userSplitAccount).length > 0 && (
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={1}
                mb={1}
                textAlign="center"
              >
                <MKTypography
                  variant="body1"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Repasse de Pagamentos
                </MKTypography>
              </MKBox>
              <MKBox mx={2} mb={1} p={1} height="auto">
                <Typography variant="body2">
                  <b>Nome da Conta:</b>{" "}
                  {
                    userSplitAccount?.recipients?.default_bank_account
                      ?.holder_name
                  }
                </Typography>
                <Typography variant="body2">
                  <b>Tipo de Conta:</b>{" "}
                  {userSplitAccount?.recipients?.default_bank_account
                    ?.holder_type === "individual"
                    ? "Pessoa Física"
                    : "Pessoa Jurídica"}
                </Typography>
                <Typography variant="body2">
                  <b>Banco:</b>{" "}
                  {userSplitAccount?.recipients?.default_bank_account?.bank ||
                    "Não informado"}{" "}
                  <b>Agência:</b>{" "}
                  {userSplitAccount?.recipients?.default_bank_account
                    ?.branch_number || ""}
                  -
                  {userSplitAccount?.recipients?.default_bank_account
                    ?.branch_check_digit || ""}{" "}
                  <b>Conta:</b>{" "}
                  {userSplitAccount?.recipients?.default_bank_account
                    ?.account_number || ""}
                  -
                  {userSplitAccount?.recipients?.default_bank_account
                    ?.account_check_digit || ""}
                </Typography>
                {userSplitAccount?.balance && (
                  <>
                    <Divider />
                    <Typography variant="body2">
                      <b>Aguardando pagamento:</b>{" "}
                      {userSplitAccount.balance?.waitingFundsAmount &&
                        parseFloat(
                          +userSplitAccount.balance.waitingFundsAmount / 100,
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </Typography>
                    <Typography variant="body2">
                      <b>Próxima tranferência:</b>{" "}
                      {userSplitAccount.balance?.availableAmount &&
                        parseFloat(
                          +userSplitAccount.balance.availableAmount / 100,
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </Typography>
                    <Typography variant="body2">
                      <b>Total transferido até o momento:</b>{" "}
                      {userSplitAccount.balance?.transferredAmount &&
                        parseFloat(
                          +userSplitAccount.balance.transferredAmount / 100,
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </Typography>
                  </>
                )}
              </MKBox>
            </Card>
          )}
          <Divider />
        </MKBox>
      </Container>

      {/* Modal para UPLOAD de arquivos */}
      <Dialog
        open={uploadModal}
        onClose={handleCloseUploadModal}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Upload de Arquivo</h3>
          </div>
          <div>
            <IconButton onClick={handleCloseUploadModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertModalInfos.message !== "" && (
            <Box sx={{ padding: "10px" }}>
              <Alert severity={alertModalInfos.type}>
                {alertModalInfos.message}
              </Alert>
            </Box>
          )}
          {Object.keys(scheduleInfo).length > 0 && (
            <>
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: "#FFEDE9",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="body1" fontWeight="700">
                  CONFIRA OS DADOS DO ATENDIMENTO:
                </Typography>
                <Typography variant="body2">
                  NOME DO PACIENTE: {scheduleInfo?.user?.name}
                </Typography>
                <Typography variant="body2">
                  HORA DO ATENDIMENTO:{" "}
                  {scheduleInfo?.date_start &&
                    dayjs(scheduleInfo.date_start)
                      .add(3, "hours")
                      .tz("America/Sao_Paulo")
                      .format("DD/MM/YYYY [às] HH:mm")}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", padding: 1 }}
              >
                <FormControl>
                  <FormLabel>INFORME O TIPO DO ARQUIVO:</FormLabel>
                  <RadioGroup
                    value={fileType}
                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <FormControlLabel
                      value="Receita Médica"
                      control={<Radio />}
                      label="Receita Médica"
                      sx={{ height: "20px" }}
                    />
                    <FormControlLabel
                      value="Atestado/Laudo"
                      control={<Radio />}
                      label="Atestado/Laudo"
                      sx={{ height: "20px" }}
                    />
                    <FormControlLabel
                      value="Relatório Médico"
                      control={<Radio />}
                      label="Relatório Médico"
                      sx={{ height: "20px" }}
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Outro Tipo"
                      sx={{ height: "20px" }}
                    />
                  </RadioGroup>
                  {fileType === "custom" && (
                    <TextField
                      onChange={(e) => setFileTypeCustom(e.target.value)}
                      helperText="Informe o tipo do arquivo"
                      hel
                      sx={{
                        height: "40px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    />
                  )}
                </FormControl>
                <TextField
                  type="file"
                  id="file"
                  helperText="Selecione o arquivo que deseja enviar"
                  onChange={(e) => setFile(e.target.files[0])}
                  sx={{ marginBottom: "20px", marginTop: "20px" }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => uploadNewFile()}
                  disabled={!file || !fileType}
                >
                  <Typography variant="buttom" sx={{ color: "#ffffff" }}>
                    Enviar Arquivo
                  </Typography>
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* Modal para assinatura de prontuário */}
      <Dialog
        open={signatureModal}
        onClose={closeSignatureModal}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Assinatura Digital de Prontuário</h3>
          </div>
          <div>
            <IconButton onClick={closeSignatureModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {appointmentSelected && (
            <DocumentSignature
              appointmentInfo={appointmentSelected}
              setSignatureModal={setSignatureModal}
              closeSignatureModal={closeSignatureModal}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Modal para edição de prontuário */}
      <Dialog
        open={medicalRecordModal}
        onClose={() => closeMedicalRecordModal()}
        maxWidth="md"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Edição de Prontuário</h3>
          </div>
          <div>
            <IconButton onClick={() => closeMedicalRecordModal()}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <ReactQuill
            id="medicalRecordInput"
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder="Insira o texto aqui..."
            value={medicalRecord}
            onChange={(content) => setMedicalRecord(content)}
            style={{
              height: `${window.innerHeight - 500}px`,
              marginBottom: "50px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            variant="contained"
            color="secondary"
            onClick={() => setMedicalRecordModal(false)}
          >
            <Typography variant="buttom" sx={{ color: "#ffffff" }}>
              Cancelar
            </Typography>
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => updateMedicalRecord()}
          >
            <Typography variant="buttom" sx={{ color: "#ffffff" }}>
              Salvar
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProfessionalAppointments;
