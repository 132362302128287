import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as Video from "twilio-video";
import { Backdrop, Card, CircularProgress, Grid } from "@mui/material";

import EletronicMedicalRecord from "components/EletronicMedicalRecord";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import MKAlert from "components/MKAlert";
import { api } from "../../lib/axios";

import MKBox from "../../components/MKBox";
import MKButton from "../../components/MKButton";
import MKTypography from "../../components/MKTypography";

function OldMeetingRoom() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const scheduleId = useParams().id;
  // eslint-disable-next-line no-unused-vars
  const [room, setRoom] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [participants, setParticipants] = useState([]);

  const [documentsModels, setDocumentsModels] = useState([]);

  const [meetingAuthorized, setMeetingAuthorized] = useState(false);
  const [reload, setReload] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [timeFinished, setTimeFinished] = useState(0);

  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });

  const getSchedules = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/schedules/${scheduleId}`);
      setAppointmentDetails(response.data);
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca do agendamento",
        type: "error",
      });
    }
    setLoading(false);
  };

  const identity =
    JSON.parse(localStorage.getItem("user"))?.id ?? `user_${scheduleId}`;

  const getAccessToken = async () => {
    try {
      setLoading(true);
      const response = await api.get("/api/twillio", {
        params: { identity, roomName: scheduleId },
      });
      setLoading(false);
      return response.data.token;
    } catch (e) {
      return null;
    }
  };

  const joinRoom = async () => {
    const token = await getAccessToken();

    if (!token) {
      console.error("Failed to join room because token is null");
    }

    Video.connect(token, {
      name: scheduleId,
      audio: true,
      video: { width: 640 },
    }).then((connectedRoom) => {
      setRoom(connectedRoom);
      connectedRoom.localParticipant.tracks.forEach((publication) => {
        publication.track.attach(localVideoRef.current);
      });

      const participantConnected = (participant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            publication.track.attach(remoteVideoRef.current);
          }
        });

        participant.on("trackSubscribed", (track) => {
          track.attach(remoteVideoRef.current);
        });
      };

      connectedRoom.on("participantConnected", participantConnected);
      connectedRoom.participants.forEach(participantConnected);

      connectedRoom.on("participantDisconnected", (participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant),
        );
      });
    });
  };

  const saveMedicalRecords = async (medicalRecords) => {
    setLoading(true);
    try {
      await api.post(`/medicalRecords`, {
        schedule_id: appointmentDetails.id,
        report: medicalRecords,
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na gravação do prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const uploadFile = async (fileStream, description) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileStream);
      formData.append("schedule_id", appointmentDetails.id);
      formData.append("description", description);

      await api.post(`/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na gravação do prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const getTemplates = async () => {
    try {
      const { data } = await api.get(`/templates`);
      setDocumentsModels(data);
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca dos modelos de prontuário.",
        type: "error",
      });
    }
  };

  const createTemplate = async (
    newTitleModel,
    newDescriptionModel,
    newTextModel,
  ) => {
    setLoading(true);
    try {
      await api.post(`/templates`, {
        title: newTitleModel,
        description: newDescriptionModel,
        content: newTextModel,
      });
      await getTemplates();
      setAlertComponent({
        message: "Modelo de prontuário criado com sucesso.",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na criação do modelo de prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const deleteTemplate = async (templateId) => {
    setLoading(true);
    try {
      await api.delete(`/templates/${templateId}`);
      await getTemplates();
      setAlertComponent({
        message: "Modelo de prontuário excluído com sucesso.",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: "Erro na exclusão do modelo de prontuário.",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("user") && authenticatedUser === null) {
      setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
    }
    if (!appointmentDetails) {
      getSchedules();
    }
  }, []);

  useEffect(() => {
    if (appointmentDetails) {
      const meetDateTime = dayjs(appointmentDetails.date_start)
        .tz("America/Sao_Paulo")
        .add(3, "hours");
      const meetLimit = meetDateTime.add(2, "hour");

      if (
        dayjs().tz("America/Sao_Paulo").isAfter(meetDateTime) &&
        dayjs().tz("America/Sao_Paulo").isBefore(meetLimit) &&
        appointmentDetails.status === "AGENDADO"
      ) {
        setMeetingAuthorized(true);
        joinRoom();
      } else {
        setMeetingAuthorized(false);
      }
    }
  }, [appointmentDetails]);

  const calculateTimeLeft = () => {
    const difference = dayjs(appointmentDetails.date_start)
      .tz("America/Sao_Paulo")
      .add(3, "hours")
      .diff(dayjs().tz("America/Sao_Paulo"));
    setTimeFinished(difference);
    if (difference > 0) {
      setReload(true);
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return false;
  };
  useEffect(() => {
    if (appointmentDetails) {
      setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    }
  }, [timeLeft, appointmentDetails]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      <Grid
        container
        textAlign="center"
        bgcolor="#f0f0f0"
        pb={5}
        height="100vh"
      >
        {alertComponent.message && (
          <Grid item xs={12} p={2}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        {meetingAuthorized && appointmentDetails && (
          <Grid item xs={12} lg={12}>
            <MKBox
              className="video"
              position={remoteVideoRef ? "absolute" : "relative"}
              width={remoteVideoRef ? "25%" : "100%"}
              m={2}
            >
              <video
                ref={localVideoRef}
                autoPlay
                muted
                playsInline
                style={{ width: "100%", height: "100%" }}
              />
            </MKBox>
            <MKBox clasName="video" m={2}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video ref={remoteVideoRef} autoPlay style={{ height: "90vh" }} />
            </MKBox>
            <MKBox>
              <MKTypography variant="h5">
                {authenticatedUser?.type === "professional"
                  ? appointmentDetails.user.name
                  : appointmentDetails.professional.name}
              </MKTypography>
            </MKBox>
          </Grid>
        )}
        {!meetingAuthorized &&
          appointmentDetails &&
          timeLeft !== null &&
          timeFinished > 0 && (
            <MKBox
              component="section"
              py={{ xs: 2, md: 5 }}
              bgColor="#f0f0f0"
              borderRadius="10px"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <MKBox>
                <MKTypography variant="h5" px={3} textAlign="center">
                  O atendimento começará em:
                </MKTypography>
                <MKTypography variant="h4" px={3} textAlign="center" py={2}>
                  <Grid container display="flex" justifyContent="space-around">
                    <MKBox>
                      <MKTypography variant="h3">
                        {String(timeLeft.days).padStart(2, "0")}
                      </MKTypography>
                      <MKTypography variant="body2">DIAS</MKTypography>
                    </MKBox>
                    <MKBox>
                      <MKTypography variant="h3">
                        {String(timeLeft.hours).padStart(2, "0")}
                      </MKTypography>
                      <MKTypography variant="body2">HORAS</MKTypography>
                    </MKBox>
                    <MKBox>
                      <MKTypography variant="h3">
                        {String(timeLeft.minutes).padStart(2, "0")}
                      </MKTypography>
                      <MKTypography variant="body2">MINUTOS</MKTypography>
                    </MKBox>
                    <MKBox>
                      <MKTypography variant="h3">
                        {String(timeLeft.seconds).padStart(2, "0")}
                      </MKTypography>
                      <MKTypography variant="body2">SEGUNDOS</MKTypography>
                    </MKBox>
                  </Grid>
                </MKTypography>
                <MKTypography variant="body2" px={3} textAlign="center">
                  Aguarde o horário de seu atendimento para entrar na sala!
                </MKTypography>
              </MKBox>
            </MKBox>
          )}
        {appointmentDetails && !timeLeft && reload && (
          <MKBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <MKButton
              type="submit"
              variant="gradient"
              color="info"
              onClick={() => window.location.reload()}
            >
              CLIQUE AQUI PARA RECARREGAR PÁGINA E ENTRAR NA SALA DE ATENDIMENTO
            </MKButton>
          </MKBox>
        )}
      </Grid>

      {authenticatedUser?.type === "professional" &&
        authenticatedUser.professional.id ===
          appointmentDetails?.professional?.id && (
          <EletronicMedicalRecord
            createTemplate={createTemplate}
            deleteTemplate={deleteTemplate}
            documentsModels={documentsModels}
            saveMedicalRecord={saveMedicalRecords}
            uploadFile={uploadFile}
          />
        )}
    </>
  );
}

export default OldMeetingRoom;
